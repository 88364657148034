jQuery(document).ready(function ($) {

	// $(window).scroll(function () {
	// 	var scroll = $(window).scrollTop();
	//
	// 	if (scroll >= 200) {
	// 		$(".header").addClass("isFixed");
	// 	} else {
	// 		$(".header").removeClass("isFixed");
	// 	}
	// });

	$('.header .links .parent').hover(function () {
		$this = $(this);
		if ($this.next('ul.submenu').length > 0) {
			$this.next('ul.submenu').show();
			$this.addClass('active');
		}
	}, function () {
		setTimeout(function () {
			if (!$this.next('ul.submenu:hover').length > 0) {
				$this.removeClass('active');
			}
		}, 1000);
	});
	$('.header .links .submenu').hover(function () {
	}, function () {
		if ($(this).closest('.item').find('.parent').length > 0) {
			$(this).closest('.item').find('.parent').removeClass('active');
		}
	});

	$('.links .menu-item-has-children .icon-arrow').on('click', function () {
		$(this).parent().toggleClass('active');
		$(this).closest('.links').toggleClass('active');
		//$(this).closest('.menuMobile').css('overflow', 'hidden');
	})
	$('.links .menu-item-has-children .back_link').on('click', function () {
		$('.links .menu-item-has-children').removeClass('active');
		$(this).closest('.links').toggleClass('active');
		//$(this).closest('.menuMobile').css('overflow', 'auto');
	})


	$('.overlay').on('click', function (e) {
		e.preventDefault();
		$(this).toggleClass('isOpen');
		$('.modalContainer').removeClass('opened');
		if ($(".menuMobile").hasClass('opened')) {
			$(".menuMobile").toggleClass('opened');
			$(".menu-toggle").removeClass('is-active');
		}
		$('body').removeClass('no-scroll');
	});
	$('.modalCloseBtn, .modalCloseAction').on('click', function (e) {
		e.preventDefault();
		$('.overlay').toggleClass('isOpen');
		$(this).closest('.modalContainer').toggleClass('opened');
	});


	$(".hamburger").on('click', function (e) {
		e.preventDefault();
		$(this).toggleClass('is-active');
		$(".menuMobile").toggleClass('opened');
		$('.overlay').toggleClass('isOpen');
		$('body').toggleClass('no-scroll');
	});
	$(".lang_switcher").on('click', function (e) {
		//e.preventDefault();
		$(".lang_switcher li:not(.current-lang)").toggleClass('active');
	});
	$(".menuMobile .parent").on('click', function (e) {
		e.preventDefault();
		$(this).toggleClass('active');
	});
	$(".footer .parent").on('click', function (e) {
		e.preventDefault();
		$(this).toggleClass('active');
	});

	(function () {
		var v = document.getElementsByClassName("player");
		for (var n = 0; n < v.length; n++) {
			var p = document.createElement("div");
			p.innerHTML = labnolThumb(v[n].dataset.id);
			p.onclick = labnolIframe;
			v[n].appendChild(p);
		}
	})();
	(function () {
		var v = document.getElementsByClassName("youtube");
		for (var n = 0; n < v.length; n++) {
			var p = document.createElement("div");
			p.innerHTML = filmThumb(v[n].dataset.id);
			p.onclick = labnolIframe;
			v[n].appendChild(p);
		}
	})();

	function labnolThumb(id) {
		return '<img class="thumbnail" src="//i.ytimg.com/vi/' + id + '/hqdefault.jpg"><div class="play-button"></div>';
	}

	function filmThumb(id) {
		return '<div class="play-button"></div>';
	}

	function labnolIframe() {
		var iframe = document.createElement("iframe");
		iframe.setAttribute("src", "//www.youtube.com/embed/" + this.parentNode.dataset.id + "?autoplay=1&autohide=2&border=0&wmode=opaque&enablejsapi=1&controls=0&showinfo=0");
		iframe.setAttribute("frameborder", "0");
		iframe.setAttribute("id", "iframe");
		this.parentNode.replaceChild(iframe, this);
	}

	$('.selfhosted .play-button').click(function () {
		var video = $(this).siblings('video');
		var pause = $(this).siblings('.pause-button');
		video.show();
		video[0].play();
		$(this).hide();
		pause.show();
	});
	$('.selfhosted .pause-button').click(function () {
		var video = $(this).siblings('video');
		var play = $(this).siblings('.play-button');
		//video.hide();
		video[0].pause();
		$(this).hide();
		play.show();
	});

	$(".totop").click(function () {
		$("html, body").animate({scrollTop: 0}, 1000);
	});
});
var observer = new IntersectionObserver(function (entries) {
	var affiliate_links = document.querySelector("#affiliate_links");
	if (entries[0].isIntersecting === true) {
		affiliate_links.style.bottom = "80px";
	} else {
		affiliate_links.style.bottom = "10px";
	}
}, {threshold: [0]});

observer.observe(document.querySelector(".footer"));
